export const ButtonOverrideStyles = {
  cursor: 'pointer',
  background: 'none',
  outline: 'none',
  boxShadow: 'none',
  border: '0',
  _hover: {
    background: 'none',
    outline: 'none',
    boxShadow: 'none',
  },
  _focus: {
    background: 'none',
    outline: 'none',
    boxShadow: 'none',
  },
  _active: {
    background: 'none',
    outline: 'none',
    boxShadow: 'none',
  },
};
